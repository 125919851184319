body {
	width: 100%;
	height: 100vh;
	background-image: url('../../Images/header-img.jpg');
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	position: relative;
	font-family: 'Roboto', sans-serif;
}

.logo {
	position: absolute;
	top: 30px;
	left: 30px;
	background-image: url('../../Images/logo.png');
	width: 220px;
	height: 101px;
	background-position: center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	border-bottom-right-radius: 27px;
	border-top-left-radius: 27px;
}

.subList {
	background: #f3f3f3;
}

.hidden-list {
	height: 0;
	padding: 0 !important;
	overflow: hidden;
}

.rotate {
	transition: 0.2s;
	transform: rotate(180deg);
}

.component {
	max-width: 1000px;
	margin: 0 auto;
	padding-top: 30px;
}

.paper-component * {
	color: white !important;
}

.paper-image-why-momento {
	background-image: url('../../Images/WhyMomento1.jpeg');
}

.paper-component-with-image {
	background-size: cover;
	background-position: center;
}

.momento-img {
	width: 100%;
	border-radius: 4px;
}

.private-lables-img {
	border-radius: 4px;
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.last-pl-img {
	margin-bottom: 14px;
}

.about-text-container,
.whymomento-text-container {
	padding: 0px 10px;
}

.about-text-container p,
.whymomento-text-container p {
	text-indent: 40px;
}

.whymomento-bottom-text {
	display: flex;
	justify-content: space-between;
}

.history-header {
	height: 100vh;
	width: 100%;
	background: url("../../Images/History_of_coffee 1.jpeg");
	background-size: cover;
	background-position: center;
}

.history-content-picture {
	background: url("../../Images/History_of_Coffee_2.jpeg");
	background-size: cover;
	background-position: center;
	height: 400px;
	min-width: 400px;
	border-radius: 4px;
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	margin-right: 30px;
}

.history-content {
	background: red;
	color: white;
	padding: 0px 30px 30px 30px;
}

.history-content-title {
	font-size: 30px;
	text-align: center;
	padding: 30px 0px;
}

.history-content-picture-and-text-container {
	display: flex;
	align-items: center;
}

.history-footer {
	background: white;
	color: #2f2f2f;
	padding: 30px 0px;
	text-align: center;
	font-size: 30px;
}

.social-container {
	position: fixed;
	width: 100%;
	bottom: 10px;
	text-align: center;
}

@media only screen and (max-width: 600px) {

	.component {
		padding: 10px;
	}

	.menu-btn {
		margin: 10px !important;
	}

	.logo {
		left: 10px;
		top: 10px;
	}

	.grid {
		max-width: 100% !important;
		flex-basis: 100% !important;
	}

	.history-content-picture-and-text-container {
		display: flex;
		flex-direction: column;
	}

	.history-content-picture {
		min-width: 90vw;
		height: 300px;
		margin-right: 0px;
	}

}